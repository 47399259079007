import React from "react";

const PoliciesPage = () => {
  return (
    <div className="mt-10 lg:mt-20 w-[90%] mx-auto sm:w-[70%] lg:w-[55%]">
      <h1 className="text-[42px] font-bold text-gray_900 fontFamily2 leading-[51px] pt-[72px] mb-[82px]">
        Our Policies
      </h1>
      <section className="w-full  mb-[82px]">
        <h3 className="text-2xl text-gray_900 font-semibold fontFamily2 mb-6">
          Cancellation Policy
        </h3>
        <article>
          <p className="fontFamily2 text-xl leading-[30px] font-normal text-gray_900">
          We kindly request that all clients confirm their appointments online and submit a non-refundable deposit. This deposit serves as a reservation guarantee and cannot be reimbursed. All appointments are subject to service charge fees, which will be included in the total sale amount. We have a strict cancellation policy in place to ensure fairness and availability for everyone. If you need to cancel or reschedule your appointment, please provide us with at least 24-hour notice from time of reservation.
          </p>
        </article>
      </section>
      <section className="w-full mb-[82px]">
        <h3 className="text-2xl text-gray_900 font-semibold fontFamily2 mb-6">
          Late Arrivals
        </h3>
        <article>
          <p className="fontFamily2 text-xl leading-[30px] font-normal text-gray_900">
		  Clients will receive the time remaining on their scheduled service and will be charged for 100% of their scheduled appointment, regardless of the actual length of service received. Clients that opt to reschedule instead of receiving a shortened service will be charged 100% of their scheduled appointment fee.           </p>
        </article>
      </section>
      <section className="w-full mb-[82px]">
        <h3 className="text-2xl text-gray_900 font-semibold fontFamily2 mb-6">
          Missed Appointment Fee
        </h3>
        <article>
          <p className="fontFamily2 text-xl leading-[30px] font-normal text-gray_900">
          Clients will be charged 100% of their scheduled appointment fee if they are no-call or no-show. 
          </p>
        </article>
      </section>
      <section className="w-full mb-[82px]">
        <h3 className="text-2xl text-gray_900 font-semibold fontFamily2 mb-6">
		Late Cancellation/Rescheduling Fee 
        </h3>
        <article>
          <p className="fontFamily2 text-xl leading-[30px] font-normal text-gray_900">
          Clients will be charged 100% of their scheduled appointment fee if they cancel or reschedule with less than 24 hours’ notice. For package or membership holders, one credit will be deducted.  
          </p>
        </article>
      </section>
      <section className="w-full mb-[82px]">
        <h3 className="text-2xl text-gray_900 font-semibold fontFamily2 mb-6">
          Refund Policy
        </h3>
        <article>
          
          <p className="fontFamily2 text-xl leading-[30px] font-normal mb-5 text-gray_900">
		  We kindly request that all clients confirm their appointments online and submit a non-refundable deposit. This deposit serves as a reservation guarantee and cannot be reimbursed. No refunds will be issued once a service has been provided. Retail items may be returned unused within 30 days for a full refund. A receipt is required. 
          </p>
        </article>
      </section>
      <div className="w-full mb-[82px]">
        <h3 className="text-2xl text-gray_900 font-semibold fontFamily2 mb-6">
          Outstanding Balance Policy
        </h3>
        <article>
          <p className="fontFamily2 text-xl leading-[30px] font-normal text-gray_900">
            All fees and/or unpaid services must be paid in full prior to scheduling future services.
          </p>
        </article>
      </div>
    </div>
  );
};

export default PoliciesPage;
