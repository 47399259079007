import React from "react";
import BookingSection from "../components/BookingSection";
import Layout from "../components/Layout";
import PoliciesPage from "../components/PoliciesPage";
import Seo from "../components/SEO";

const META_DATA = {
  title: "Policies | Recovery & Wellness Studio | HighKey Balance",
  description: `Familiarize yourself with our policies at HighKey Balance, Washington D.C.'s premiere all-in-one wellness and recovery center. Book a session today!`,
};

const Policies = () => {
  return (
    <Layout>
      <Seo {...META_DATA} />
      <PoliciesPage />
      <BookingSection />
    </Layout>
  );
};

export default Policies;
